import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import Layout3Columns from "../layout/Layout3Columns"
import Button from "../buttons/Button"
import { devices } from "../../settings"

import DesignImage from "../images/phone-hero-design"
import BuildImage from "../images/phone-hero-build"
import InstalledImage from "../images/phone-hero"

const Section = styled.div`
    padding: 0px;
    
    @media ${devices.mobile} {
        padding: 10px;
    }  

    h2 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
        font-size: 25px;
        margin: 0px;
    }
    p {
        margin-top: 5px;
        font-size: 16px;
    }
    label {
        text-align: center;
        font-size: 22px;
        padding: 5px 15px;
        display: block;
    }
    img {
        border-radius: 4px;
    }
    > div {
        margin-bottom: 20px;
    }
`

function DesignBuildInstallSection(props) {
    return (
        <Section>
            <h2>
                Design &amp; Price Live Online
            </h2>
            <p>
                Use our online sign builder to design and price a sign yourself.
            </p>

            <Layout3Columns>
                <div>
                    <DesignImage />
                    <label>Designed</label>
                </div>
                <div>
                    <BuildImage />
                    <label>Built</label>
                </div>
                <div>
                    <InstalledImage />
                    <label>Installed</label>
                </div>
            </Layout3Columns>

            {false && <Link to="/builder/?from=DesignBuildInstall">
                <Button 
                    label="Design &amp; Price Now" 
                    color="orange"
                    size="large"
                />
            </Link>}
        </Section>
    );
}

export default DesignBuildInstallSection