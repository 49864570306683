
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-phone-hero-build"
        alt="SignMonkey Sign Being Built"
        backgroundColor={"#c6c3bd"}
        backgroundPreview={null}
        aspectRatio={75}  
        width={262} 
        height={196.5} 
        sizes={[400, 262]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/phone-hero-build-400.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/phone-hero-build-262.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/phone-hero-build-400.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/phone-hero-build-262.jpg?v25"
        ]}
    />   
)
